 <link href= "https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet"> 

<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> {{data.name}}
	<button [mat-dialog-close]="true" class="close-button">
  <mat-icon class="close-icon"> close </mat-icon>
</button>
</h1>
<mat-dialog-content>
<div class="buttons">
<button type="button" class="save wideButton"  [disabled]="startDisabled" (click)="onStart();">
    <span>Capture Audio</span>
</button>

<button type="button" class="save wideButton" [disabled]="false" (click)="onStop();">
     <span>Stop</span>
</button>
</div>


<canvas #canvas width = "2000" height = "700" style="width:90%; height:70%;margin-left: 4%; margin-top: 1%;" ></canvas>
<div style="padding-left:20px; padding-right:20px">
I just wanted to use html5 to do a capture an audio signal and do an FFT on it.  I show the first 8 frequencies if it has the frequencies. 
I used webgl's fft function and it dosen't return the phase shift of each frequency.  If you haven't seen this explanation of Fourier 
transforms check this out <a href="https://www.youtube.com/watch?v=spUNpyF58BY">3Blue1Brown Fourier</a>.  
    
</div>
</mat-dialog-content>
<mat-dialog-actions>
		<div class="buttons">
		<button class="save" mat-button [mat-dialog-close]="true" (click)="closeDialog()">Close</button>
		</div> 
</mat-dialog-actions>






