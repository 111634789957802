	// tslint:disable: indent
import { Component, OnInit } from '@angular/core';
import { merge, Observable, Subject}  from 'rxjs';
import { map, tap, debounceTime, takeUntil } from 'rxjs/operators';
import {
	ListeningStarted,
	SpeakingStarted,
	RecognizedTextAction,
} from '../classes/models';
import { SenseService } from '../services/sense.service';

@Component({
	selector: 'app-bot',
	templateUrl: './bot.component.html',
	styleUrls: ['./bot.component.css'],
})
export class BotComponent implements OnInit {
	destroy$ = new Subject();

	recognized$ = this.senseService.getType(RecognizedTextAction);
	state$: Observable<string>;
	message$: Observable<string>;
	micAccess$ = this.senseService.hasMicrofonAccess$;
	active = false;

	constructor(private senseService: SenseService) {
	}

	// tslint:disable: typedef
	getImageClass() {
		return {
			image: true,
		};
	}

	ngOnInit() {}

	// tslint:disable-next-line: use-lifecycle-interface
	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}

	activate() {
		if (false === this.active) {
			this.message$ = this.recognized$.pipe(tap(console.log));
			const speaking$ = this.senseService
				.getType(SpeakingStarted)
				.pipe(map(() => 'SPEAKING'));

			const listening$ = this.senseService
				.getType(ListeningStarted)
				.pipe(map(() => 'LISTENING'));

			this.state$ = merge(speaking$, listening$);

			this.recognized$
				.pipe(
					debounceTime(100),
					tap((msg) => {
						this.senseService.speak(msg);
						this.message$ = msg;
					}, takeUntil(this.destroy$))
				)
				.subscribe();
			document.getElementById('btnActiveId').innerHTML = 'Stop Listening';
			this.active = true;
		} else {
			document.getElementById('btnActiveId').innerHTML = 'Start Listening';
			this.active = false;
			this.destroy$.next();
			this.destroy$.complete();
	}
}
}
