
import { Output, EventEmitter } from '@angular/core';
import { Injectable, NgZone } from '@angular/core';

@Injectable({
providedIn: 'root',
})

export class EventService {
    public IS_PLAYING_TTS: boolean;
    public IS_VIDEO_TTS: boolean;
    @Output() public audioPlaying: EventEmitter<any> = new EventEmitter();
    @Output() public audioStopping: EventEmitter<any> = new EventEmitter();
    @Output() public resetInterface: EventEmitter<any> = new EventEmitter();

    @Output() public videoPlaying: EventEmitter<any> = new EventEmitter();
    @Output() public videoStopping: EventEmitter<any> = new EventEmitter();
    @Output() public resetVideoInterface: EventEmitter<any> = new EventEmitter();

    constructor() {
        this.IS_PLAYING_TTS = false;
        this.IS_VIDEO_TTS = false;
    }

    // tslint:disable: typedef
    setIsPlaying(isPlaying: boolean) {/*  */
        this.IS_PLAYING_TTS = isPlaying;
    }

    getIsPlaying() {
        return this.IS_PLAYING_TTS;
    }
    setIsVideoing(isPlaying: boolean) {
        this.IS_VIDEO_TTS = isPlaying;
    }

    getIsVideoing() {
        return this.IS_VIDEO_TTS;
    }

}
