 <!-- Toolbar -->
<div class="toolbar">
  <svg id="bicycle" alt="Bicycle" xmlns="http://www.w3.org/2000/svg" width="100%" height="35" top="0"  viewBox="0 0 50 20" >
      <g>
        <g id="Group_47" data-name="Group 47">
          <image width="30" height="20" style="bottom: 0;" xlink:href="./assets/P51_Mustang.svg" />
          <text x = "75" y="18" class="headingText"> Patrick Webster Software Engineer,   Developer,   Full Stack Web Developer</text>
        </g>
        <animateTransform attributeName="transform" type="translate" from="0 0" to="-200" begin="0s" dur="4s" repeatCount="1" fill="freeze"  />
      </g>
  </svg>
</div>

<div class="content" role="main" >
  <div class="card-container">
    <div class="card">
    <div  class="card2" id="idHome"  target="_blank" rel="noopener"  (click)="onResumeClick()">
      <svg xmlns="http://www.w3.org/2000/svg"  width="100%"  top="0"  viewBox="0 0 30 30" >
        <g class="material-icons" >
          <image width="100" height="30" x= "-75" y = "0" xlink:href="./assets/P51_Mustang.svg" />
          <text id="homeTextId"  x = "0" y="17" class="tabText"> Resume</text>
        </g>
      </svg>
      </div>
      <div class="card3"  (click)="onResumeClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 642 351">
          <g>
            <image  xlink:href="./assets/img/Resume.png" />
          </g>
        </svg>
      </div>
    </div>

    <div class="card">
    <div  class="card2" id="idHome"  target="_blank" rel="noopener"  (click)="onNarativeClick()">
      <svg xmlns="http://www.w3.org/2000/svg"  width="100%"  top="0"  viewBox="0 0 30 30" >
        <g class="material-icons" >
          <image width="100" height="30" x= "-75" y = "0" xlink:href="./assets/P51_Mustang.svg" />
          <text id="homeTextId"  x = "0" y="17" class="tabText"> Background</text>
        </g>
      </svg>
      </div>
      <div class="card3"  (click)="onNarativeClick()">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height= "100%" viewBox="0 0 1242 670">
          <g>
            <image  xlink:href="./assets/img/background.png" />
          </g>
        </svg>
      </div>
    </div>


    <div class="card">
    <div  class="card2" id="idHome"  target="_blank" rel="noopener"  (click)="onImageClick()">
      <svg xmlns="http://www.w3.org/2000/svg"  width="100%"  top="0"  viewBox="0 0 30 30" >
        <g class="material-icons" >
          <image width="100" height="30" x= "-75" y = "0" xlink:href="./assets/P51_Mustang.svg" />
          <text id="homeTextId"  x = "0" y="17" class="tabText"> Audio/Video</text>
        </g>
      </svg>
      </div>
      <div class="card3"  (click)="onImageClick()">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 399 208">
        <g>
          <image  xlink:href="./assets/img/audio.png" />
        </g>
      </svg>
    </div>
    </div>

    
 

    <div class="card">
    <div  class="card2" id="idHome"  target="_blank" rel="noopener"  (click)="onSignalClick()">
      <svg xmlns="http://www.w3.org/2000/svg"  width="100%"  top="0"  viewBox="0 0 30 30" >
        <g class="material-icons" >
          <image width="100" height="30" x= "-95" y = "0" xlink:href="./assets/P51_Mustang.svg" />
          <text id="homeTextId"  x = "-20" y="17" class="tabText"> Signal Analysis</text>
        </g>
      </svg>
      </div>
      <div class="card3"  (click)="onSignalClick()">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 642 351">
        <g>
          <image  xlink:href="./assets/img/signal.png" />
        </g>
      </svg>
    </div>
    </div>

</div>
  <!-- Footer -->
  <footer styles="height:5px">
  </footer>


