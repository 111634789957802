
import { Fullfillment } from '../models/fullfillment.models';

export class FullfillmentService {
    public Fullfillment: Fullfillment;
    public matches: any;
    private defaultMessage: string;

    constructor() {
        this.defaultMessage = 'Press \'ask a question\' button';
        this.matches = [];
        this.Fullfillment = {
            UTTERANCE: this.defaultMessage,
            FULLFILLMENTS: []
        };
    }
    // tslint:disable: typedef
    getFullfillment() {
        return this.Fullfillment;
    }
    setFullfillments(data) {
        // console.log(data);
        if (data == null) {
            return;
        }
        if (data.UTTERANCE) {
            this.Fullfillment.UTTERANCE = data.UTTERANCE;
        } else {
            if (data.PAYLOAD) {
                const payload = JSON.parse(data.PAYLOAD);
                this.matches.push({
                    QUESTION: payload.QUESTION,
                    ANSWER: data.TRANSLATED_Fullfillment
                    // ANSWER: payload.ANSWER
                });
            } else {
                this.matches.push({
                    QUESTION: data.INTENT_NAME,
                    ANSWER: data.TRANSLATED_Fullfillment,
                    AUDIO: data.AUDIO
                });
            }
            this.Fullfillment.FULLFILLMENTS = this.matches;
        }
    }
    clearAll() {
        this.matches = [];
        this.Fullfillment.UTTERANCE = this.defaultMessage;
        return this.Fullfillment.FULLFILLMENTS = [];
    }
}
