import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { CameraComponent } from './video/camera/camera.component';
import { SoundComponent } from './sound/sound.component';
import { Draw3dComponent } from './draw3d/draw3d.component';
import { AiModuleComponent } from './ai-module/ai-module.component';
import { HistoryComponent } from './history/history.component';
import { SignalComponent } from './signal/signal.component';

const routes: Routes = [
      { path: 'home', component: HomeComponent },
      { path: 'resume', component: ResumeComponent },
      { path: 'video', component: CameraComponent },
      { path: 'sound', component: SoundComponent },
      { path: 'draw3d', component: Draw3dComponent },
      { path: 'aisearch', component: AiModuleComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'signal', component: SignalComponent },
      { path: '', redirectTo: '/home', pathMatch: 'full'},
      { path: '**', redirectTo: 'home', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: true, // <-- debugging purposes only
      }
    )
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
