import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})

export class CameraComponent  implements OnInit {

    @ViewChild('video', { static: true }) videoElement: ElementRef;
    @ViewChild('canvas', { static: true }) canvas: ElementRef;
    isRecording = false;
    @ViewChild('photo', { static: true }) photo: ElementRef;
 constraints = {
    video: {
        facingMode: 'environment',
        width: { ideal: 4096 },
        height: { ideal: 2160 }
    // },
    //  audio: {
    //     type: 'audio',
    //     mimeType: 'audio/webm',
    //     sampleRate: 44100, // this sampleRate should be the same in your server code
    //     numberOfAudioChannels: 1,
    //     timeSlice: 10,
    //     audioBitsPerSecond: 128000,
    //     desiredSampRate: 2048,
    }
};
videoWidth = 0;
videoHeight = 0;
constructor(private renderer: Renderer2) { }

ngOnInit() {
}

startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
 navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
        alert('Sorry, camera not available.');
    }
}
handleError(error) {
    console.log('Error: ', error);
}

capture() {
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
    this.renderer.setProperty(this.photo.nativeElement, 'width', this.videoWidth/2);
    this.renderer.setProperty(this.photo.nativeElement, 'height', this.videoHeight/2);
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.photo.nativeElement.setAttribute('src', data);
}
attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = this.videoElement.nativeElement.videoHeight;
        this.videoWidth = this.videoElement.nativeElement.videoWidth;
    } );
}
}

