 <link href= "https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet"> 

<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> {{data.name}}
	<button [mat-dialog-close]="true" class="close-button">
  <mat-icon class="close-icon"> close </mat-icon>
</button>
</h1>
<mat-dialog-content class="modal" >
	<div class="signal">
		<div style="width: 45%;">
			<app-camera>
			</app-camera>	
		</div>
		<div style="width: 45%;">
			<app-bot></app-bot>
		</div>
	</div>
</mat-dialog-content>
    <mat-dialog-actions>
		<div class="buttons">
		<button class="close" mat-button [mat-dialog-close]='true' (click)="closeDialog()">Close</button> 
		</div>
    </mat-dialog-actions>
