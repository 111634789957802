import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.css']
})
export class SignalComponent implements OnInit {

  constructor() { }

  // tslint:disable-next-line: typedef
  ngOnInit() {
  }

}
