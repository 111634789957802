import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { SoundComponent } from './sound/sound.component';
import { ResumeComponent } from './resume/resume.component';
import { MicrophoneComponent } from './signal/microphone/microphone.component';
import { HistoryComponent } from './history/history.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { AbsoluteSourceSpan } from '@angular/compiler';
import {Draw3dComponent } from './draw3d/draw3d.component';
import {AiModuleComponent } from './ai-module/ai-module.component';
import { HomeComponent } from './home/home.component';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'Patrick Webster, Software Engineer,  Developer,  Full Stack Web Developer';
  menuActive = false;
  curTab = 'home';

  subscription: Subscription;

  constructor(private router: Router, public dialog: MatDialog) {
   this.subscription = router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !router.navigated;
          console.log('subscription');
        }
      });
      // tslint:disable-next-line: only-arrow-functions
      window.onbeforeunload = function (event) {
          console.log('onbeforeunload onbeforeunload onbeforeunload onbeforeunload');
//          event.preventDefault();
      return 'Are you sure you want to reload?'
       }
      };
   ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  // tslint:disable: typedef
  onResumeClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '75vw' ;
      dialogConfig.height = '80vh' ;
      dialogConfig.position = {
      top:  '0%',
      left: '10%',
      };
      dialogConfig.data = {
            name: 'Resume',
      };
      const dialogRef = this.dialog.open(ResumeComponent, dialogConfig);
  }
  onHomeClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '65vw' ;
      dialogConfig.height = '75vh' ;
      dialogConfig.data = {
            name: 'Home',
      };
      const dialogRef = this.dialog.open(HomeComponent, dialogConfig);
  }
  onSignalClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '75vw' ;
      dialogConfig.height = '68vh' ;
      dialogConfig.data = {
            name: 'Signal Analysis',
      };
      const dialogRef = this.dialog.open(MicrophoneComponent, dialogConfig);
  }

  onImageClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'audioPane';
      dialogConfig.width = '70vw' ;
      dialogConfig.height = '60vh' ;
      dialogConfig.data = {
            name: 'Audio/Video',
      };
      const dialogRef = this.dialog.open(SoundComponent, dialogConfig);

  }
  onSearchClick() {
       const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.position = {
      top:  '0%',
      left: '10%',
      };
      dialogConfig.width = '75vw' ;
      dialogConfig.height = '65vh' ;
      dialogConfig.data = {
            name: 'AI',
      };
      const dialogRef = this.dialog.open(AiModuleComponent, dialogConfig);
  }
  onDraw3glClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.position = {
      top:  '0%',
      left: '10%',
      };
      dialogConfig.width = '75vw' ;
      dialogConfig.height = '65vh' ;
      dialogConfig.data = {
            name: '3D Graphics',
      };
      const dialogRef = this.dialog.open(Draw3dComponent, dialogConfig);
  }
  onNarativeClick() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.position = {
      top:  '0%',
      left: '10%',
      };
      dialogConfig.width = '75vw' ;
      dialogConfig.height = '80vh' ;
      dialogConfig.data = {
            name: 'Background',
      };
      const dialogRef = this.dialog.open(HistoryComponent, dialogConfig);
  }

  setCurTab(newTab: string) {
      console.log('vurtab', newTab);
        const curt = this.curTab.concat('TextId');
        const curt2 = this.curTab.concat('TabId');
        document.getElementById(this.curTab.concat('TextId')).classList.remove('selectedTextColor');
        document.getElementById(this.curTab.concat('TabId')).classList.remove('selectedTab');
        document.getElementById(newTab.concat('TextId')).classList.add('selectedTextColor');
        document.getElementById(newTab.concat('TextId')).classList.add('tabText');
        document.getElementById(newTab.concat('TabId')).classList.add('card');
        document.getElementById(newTab.concat('TabId')).classList.add('selectedTab');
        this.curTab = newTab;
  }
}
