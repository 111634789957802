
import { Component, OnInit, ElementRef } from '@angular/core';
import { FullfillmentService } from '../services/fullfillment.service';
import { Fullfillment } from '../models/fullfillment.models';

@Component({
  selector: 'app-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss']
})

export class WaveformComponent {
    public canvasCtx: CanvasRenderingContext2D;
    public Fullfillment: Fullfillment;
    public source: MediaStreamAudioSourceNode;
    public audioCtx: AudioContext;
    public analyser: AnalyserNode;
    public bufferLength: number;
    public dataArray: any;
    public canvasWidth: number;
    public canvasHeight: number;
    public displace: number;
    public stopCollecting: boolean;

    constructor(public FullfillmentService: FullfillmentService) {
        this.Fullfillment = this.FullfillmentService.getFullfillment();
}

    public init() {
        const canvas = document.querySelector('canvas');
        this.canvasCtx = canvas.getContext('2d');
        this.canvasWidth = canvas.width;
        this.canvasHeight = canvas.height;
        this.displace = 0;
        this.stopCollecting = false;
    }

    public start(stream: MediaStream) {
        this.init();
        this.audioCtx = new AudioContext();
        this.analyser = this.audioCtx.createAnalyser();
        this.analyser.smoothingTimeConstant = 0;
        this.analyser.fftSize = 2048;
        this.source = this.audioCtx.createMediaStreamSource(stream);
        this.source.connect(this.analyser);
        const canvas = document.querySelector('canvas');
        this.canvasCtx = canvas.getContext('2d');
        this.canvasCtx.save();
    }

    public stop() {
        if (this.audioCtx.state === 'running') {
            this.audioCtx.suspend();
            this.source.disconnect(this.analyser);
            this.canvasCtx.fillStyle = '#000000';
            this.canvasCtx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.canvasCtx.lineWidth = 1;
            this.canvasCtx.strokeStyle = 'rgb(255, 255, 255)';
            this.canvasCtx.beginPath();
            this.canvasCtx.lineTo(this.canvasWidth, this.canvasHeight / 2);
            this.canvasCtx.stroke();
            this.displace = 0;
        }
    }

   public end() {
        if (this.audioCtx.state === 'running') {
            this.audioCtx.suspend();
        }
    }

    visualize() {
        this.canvasCtx.restore();
        this.canvasCtx.fillStyle = '#1a1a1a';
        this.canvasCtx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.bufferLength = 2 * this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(this.bufferLength);
        this.analyser.getByteTimeDomainData (this.dataArray);
        this.canvasCtx.fillStyle = '#1a1a1a';
        this.canvasCtx.lineWidth = 1;
        this.canvasCtx.strokeStyle = 'rgb(255, 255, 255)';
        this.canvasCtx.fillStyle = '#1a1a1a';
        this.canvasCtx.lineWidth = 1;
        this.canvasCtx.strokeStyle = 'rgb(255, 0, 0)';
        this.canvasCtx.translate(0, 60);
        this.displace = 60;
        this.drawVoice();

        this.analyser.getByteFrequencyData(this.dataArray);
        for (let i = 0; i < 11; i++) {
            this.canvasCtx.strokeStyle = 'rgb(0, 255, 0)';
            this.drawWave(this.dataArray[i], i);
        }
        this.canvasCtx.translate(0, -1 * this.displace);
        this.displace = 0;
    }
public drawWave(amplitude, frequency) {
        // trig is the trigonometric function to be used: sin or cos
        if (amplitude === 0) {
            return;
        }
        this.canvasCtx.beginPath();
        this.canvasCtx.lineWidth = .5;

        let y = 0;
        this.displace += 40;
        this.canvasCtx.translate(0, 40);
        this.canvasCtx.moveTo(0, 0);
        this.canvasCtx.lineTo(this.canvasWidth, 0);
        this.canvasCtx.moveTo(0, 0);
        if (frequency === 0){ return; }
        for (let i = 0; i < ( this.canvasWidth); i++) {
                y = (-1 * amplitude) * Math.cos(i * frequency / 360);
                this.canvasCtx.lineTo(i, y);
            }

        this.canvasCtx.stroke();
        }
public drawVoice() {
            if (this.bufferLength === 0) {return; }
            this.canvasCtx.beginPath();
            this.canvasCtx.strokeStyle = 'rgb(255, 0, 0)';
            this.canvasCtx.moveTo(0, 0);
            this.canvasCtx.lineWidth = 1;
            this.canvasCtx.lineTo(this.canvasWidth, 0);
            const sliceWidth = this.canvasWidth * 1.0 / this.bufferLength;
            this.canvasCtx.strokeStyle = 'rgb(255, 0, 250)';
            let x = 0;
            let y = 0;
            for (let i = 0; i < this.bufferLength; i++) {
                y = (-1 * this.dataArray[i]) + 128;

                if (i === 0) {
                    this.canvasCtx.moveTo(x, y);
                } else {
                    this.canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }
            this.canvasCtx.stroke();
        }
    }





