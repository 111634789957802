import { Component, ElementRef, OnInit, Renderer2, ViewChild, Inject } from '@angular/core';
import { Router} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// app
import { browserRefresh } from '../app.component';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
   @ViewChild('canvas', { static: true }) canvas: ElementRef;
   @ViewChild('vger', { static: true }) vger: ElementRef;

  public browserRefresh: boolean;

  constructor(private renderer: Renderer2, private router: Router,
    public dialogRef: MatDialogRef<HomeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

ngOnInit() {
   this.browserRefresh = browserRefresh;
  }

capture() {
    const ctx = this.canvas.nativeElement.getContext('2d');
    this.vger.nativeElement.src = 'assets/img/p51.jpg';
    this.vger.nativeElement.onload = () => {
   };
}

clockwise() {
  this.drawRotated(90);
}
counterclockwise() {
  this.drawRotated(90);
}
drawRotated(angle) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    ctx.rotate( 45 * Math.PI / 180);
    ctx.drawImage(this.vger.nativeElement, 0, 0, 250, 194, 80, -40, 70, 50);
    ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    ctx.restore();
    ctx.rotate( 45 * Math.PI / 180);
    ctx.drawImage(this.vger.nativeElement, 0, 0, 250, 194, 100, -150, 50, 50);
    ctx.restore();

}

}
