<div class="alert" *ngIf="(micAccess$ | async)" >
	No microphone access! You need to give micphone access. (You may click refresh
	button to be prompted mic access pop up)
</div>
<div  class="visualizer"  style= "width: 100%; ">
	<div>
		<div>
			<button id = "btnActiveId" class="button wideButton" (click)="activate()">Start Listening</button>
			<h4>
				 {{ state$ | async }}
			</h4>
			<textarea class="message-box">  {{message$}}
			</textarea>
		</div>
	</div>
</div>


