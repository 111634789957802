import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { Draw3dComponent } from './draw3d/draw3d.component';
import { HistoryComponent } from './history/history.component';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { AudioRecordingService } from './signal/audio-recording.service';
import { MicrophoneComponent } from './signal/microphone/microphone.component';
import { EventService} from './signal/services/event.service';
import { FullfillmentService} from './signal/services/fullfillment.service';
import { IoService } from './signal/services/io.service';
import { SignalComponent } from './signal/signal.component';
import { WaveformComponent } from './signal/waveform/waveform.component';
import { BotComponent } from './sound/bot/bot.component';
import { SettingsComponent } from './sound/settings/settings.component';
import { SoundComponent } from './sound/sound.component';
import { CameraComponent } from './video/camera/camera.component';
import { VideoformComponent } from './video/videoform/videoform.component';
import { AiModuleComponent } from './ai-module/ai-module.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { SpeechModule } from '../lib/';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatIconModule} from '@angular/material/icon';

export let browserRefresh = false;



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HistoryComponent,
    ResumeComponent,
    Draw3dComponent,
    SoundComponent,
    BotComponent,
    SettingsComponent,
    MicrophoneComponent,
    SignalComponent,
    WaveformComponent,
    CameraComponent,
    AiModuleComponent,
    VideoformComponent,
],
imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SpeechModule,
    MatDialogModule,
    DragDropModule,
    MatIconModule,
],
  providers: [
    {provide: 'SPEECH_LANG', useValue: 'en-US' },
    IoService,
    FullfillmentService,
    EventService,
    AudioRecordingService,
    AppRoutingModule,
    RouterModule,
  ],
  bootstrap: [AppComponent],
   entryComponents: [SoundComponent, ResumeComponent, MicrophoneComponent, HistoryComponent, AiModuleComponent, Draw3dComponent,
     HomeComponent]
})

export class AppModule {
    constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }

}
