 <link href= "https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet"> 

<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> {{data.name}}
	<button [mat-dialog-close]="true" class="close-button">
  <mat-icon class="close-icon"> close </mat-icon>
</button>
</h1>

<mat-dialog-content class="modal" >
<div>
<div style = "float:right; width:40%;color:#999999; font-size: .8rem; padding: 2% 5% 0 5% ;" >
One purpose of this website is to highlight some of the abilities I have to offer to make better websites.&nbsp;&nbsp;  Normal websites 
can be brought to life and made easier to use with a more creative design and using the many new tools available to web programmers. &nbsp;&nbsp; So far 
this is a  normal website. &nbsp;&nbsp; It will change as I explore the new options I have to use like maybe instead of just tabs a more 3d interactive display
where the tabs are essentially distant objects displaying just a little information that shows more as you mouse over it and then focus in on it. &nbsp;&nbsp; 
Kind of like how we see the world. &nbsp;&nbsp;And adding speech recognition, talking programs, video, video recognition, video recording, 2 and 3d graphics, 
and then using AI to help search words and pictures and more are realizable today for all of us.
</div>
<div>
<img src="../../assets/img/header5.png" class= "voyager">
	</div>
<div style = "float:right; width:90%;color:#999999; font-size:.8rem; padding: 0 5% 0 5% ;" >
So to bring myself up to speed on these new technologies I am first just learning enough about a topic to do something with it and then move on to the next topic.&nbsp;&nbsp;  
	You  can see I did voice capture and speech recognition and then graphed the sound in 2D graphics and then do an FFT on it graphing some of the frequencies.&nbsp;&nbsp;   
	And then I wanted to capture a video stream and manipulate it again in 2D graphics as I show in the imaging tab.&nbsp;&nbsp;   It is not clean but I got the basics working.
	&nbsp;&nbsp;   Next, I want to use Web3G.&nbsp;&nbsp; I see it uses a lot of matrix manipulations and I�ve done a lot of linear algebra to the point of seeing a Fourier 
	Transform as being an infinite dimensional matrix operating on an infinite dimension vector producing and infinite dimensional transform vector.&nbsp;&nbsp;
	 Or was that Eigenvalues and EigenVectors.&nbsp;&nbsp;   I think God is in there somewhere.&nbsp;&nbsp;   Anyway, I am looking forward to using my linear algebra.&nbsp;&nbsp;   
	 And finally, I plan to get to AI starting with something like Google's Tensor Flow. 

</div>
</div>

</mat-dialog-content>


