
import { Component, Input, OnInit, Inject } from '@angular/core';
import { IoService } from '../services/io.service';
import { WaveformComponent } from '../waveform/waveform.component';
import { EventService } from '../services/event.service';
import { FullfillmentService } from '../services/fullfillment.service';
import RecordRTC from '../../../../node_modules/recordrtc';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-microphone',
  templateUrl: './microphone.component.html',
  styleUrls: ['./microphone.component.scss']
})

export class MicrophoneComponent {
    @Input() waveform: WaveformComponent;
    public utterance: any;
    public recordAudio: any;
    public startDisabled: boolean;
    public stopDisabled: boolean;

    constructor(public FulfillmentService: FullfillmentService, public ioService: IoService, public eventService: EventService,
          public dialogRef: MatDialogRef<MicrophoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      const me = this;
      me.startDisabled = false;
      me.eventService.audioPlaying.subscribe(() => {
        me.onStop();
      });
      me.eventService.resetInterface.subscribe(() => {
        me.onStop(); // stop recording & waveform
        me.eventService.audioStopping.emit(); // stop playing audio
        me.reset(); // reset the interface
      });
      me.waveform = new WaveformComponent(FulfillmentService);
    }


  // tslint:disable-next-line: typedef
    // tslint:disable-next-line: use-lifecycle-interface
    ngOnInit() {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    // tslint:disable: typedef
    onStart() {
      const me = this;
      me.startDisabled = true;
      // make use of HTML 5/WebRTC, JavaScript getUserMedia()
      // to capture the browser microphone stream
      navigator.mediaDevices.getUserMedia({
          audio: true
      // tslint:disable-next-line: only-arrow-functions
      }).then(function(stream: MediaStream) {
          me.recordAudio = new RecordRTC.StereoAudioRecorder(stream, {
              type: 'audio',
              mimeType: 'audio/webm',
              sampleRate: 44100, // this sampleRate should be the same in your server code


              // Dialogflow / STT requires mono audio
              numberOfAudioChannels: 1,

              // get intervals based blobs
              // value in milliseconds
              // as you might not want to make detect calls every seconds
              timeSlice: 10,

              // only for audio track
               audioBitsPerSecond: 128000,

              // used by StereoAudioRecorder
              // the range 22050 to 96000.
              // let us force 16khz recording:
              desiredSampRate: 2048,

              // as soon as the stream is available
              ondataavailable(blob) {
                if (!(me.eventService.getIsPlaying())) {
                  me.ioService.sendBinaryStream(blob);
                  me.waveform.visualize();
                }
              }
          });
          me.recordAudio.record();
          // recording started
          me.waveform.start(stream);
      // tslint:disable-next-line: only-arrow-functions
      }).catch(function(error) {
          console.error(JSON.stringify(error));
      });
    }

    onStop() {
      // recording stopped
      this.startDisabled = false;
      // stop audio recorder
      this.recordAudio.stop();
      this.waveform.end();
    }

    reset() {
      this.FulfillmentService.clearAll();
    }

    popOut() {
  }
}
