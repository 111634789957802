 <link href= "https://fonts.googleapis.com/icon?family=Material+Icons&display=block" rel="stylesheet"> 

<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> {{data.name}}
	<button [mat-dialog-close]="true" class="close-button">
  <mat-icon class="close-icon"> close </mat-icon>
</button>
</h1>
<mat-dialog-content>
	<h2 style="padding-bottom: 20px">
        Summary 
		</h2>

	<div>
<a class = "indent2">Experience:</a>

    <ul style="font-size: 20px;">
	
		<li>32 years of Applications development.</li>
		<li>21 years in C++.</li>
		<li>5 years in java Web development</li>
		<li>4 years in .NET Web development.</li>
		<li>12 years Relational Database Application Development.</li>
		</ul>

<a class = "indent2">Tools & Skills:</a>

    <ul ID="BulletedList2">
		<li>Languages -  C++, C, Java, Java script, Jquery, HTML, CSS,  XML, Type Script, PHP, VB/C#/ASP .Net). </li>
        <li>IDE's - Eclipse, NetBeans, and Visual Studio</li>
        <li>Source Systems - Source Safe, Subversion.</li>
        <li>Databases - Oracle, MS SQL, MySql, DB2.</li>
        <li>Technologies - Angular, CUDA (massive parallel processing, Visio.</li>
        <li>Advanced Mathematics - Image Analysis, Statistics, Process Control.</li>
	</ul>
<h2>Education</h2>

<p class = "indent4paragraph" style="padding-bottom: 10px">
Bachelor of Science in Engineering Mathematics, University of Arizona, 1989
</p>
   
<h2>
	Work History 
</h2>


<a class = "indent4">Software Engineer/Applications Developer</a><br />
<div class = "indent4paragraph">
09/15-09/20 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="h5"><a id ="HistPhoto1" href="https://www.pingwind.com/">Pingwind</a>, &nbsp; &nbsp; Tucson, Az, Remote</span> &nbsp;&nbsp;&nbsp;
Remote work for the Veterans Administration Consolidated Mail Outpatient Pharmacy.   Worked with visual studio fixing bugs and adding features to existing C++ programs and using MSSQL databases to find, 
fix, test, and release fixes.  We used Microsoft Teams using its source saving features tied in with all the other programmers and the review process.
</div>

<div class = "indent4paragraph">
09/15-09/20 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="h5"><a id ="HistPhoto1" href="https://exetergov.com/">Exeter Government Services </a>, &nbsp; &nbsp; Tucson, Az</span> &nbsp;&nbsp;&nbsp;
Worked at <a href = "https://www.dm.af.mil/About-DM/Units/Mission-Partners/309-AMARG/" >AMARG </a>as Full stack developer maintained Tomcat Webservers on Windows and Linux
 platforms as well as developed applications and deployed them to the servers. <a routerlink = "https://www.dm.af.mil/About-DM/Units/Mission-Partners/309-AMARG/" >more+++ </a> 
</div>
<div class = "indent4paragraph">
02/15-09/15 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="h5"><a id ="HistPhoto2" href="https://www.alutiiq.com/">Alutiiq</a>,  &nbsp; &nbsp;Fort Huachuca, Az</span> &nbsp;&nbsp;&nbsp;
Developed educational lessons using a custom language in xml that a proprietary interpreter parsed to created Adobe Flash code.  
</div>
<div class = "indent4paragraph">
06/12-11/10 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="h5"><a id ="HistPhoto3">PDWTheWebster </a>,  &nbsp; &nbsp;Tucson, Az</span> &nbsp;&nbsp;&nbsp;
Freelance web developer developing web sites using  C#, MVC, Webforms, NuGet, Entity Framework and more of the tools available in Visual Studio 2013, Java script, PHP and MySql.
</div>
<div class = "indent4paragraph">03/11-6/12 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="h5"> <a id ="HistEnviroText" href="https://www.envirotestltd.com/">Envirotest</a>, Tucson, Az</span>
Application support and development for a  drive by car exhaust monitoring product the RSD 5000. &nbsp;&nbsp;The design is an MVC design and uses VB/ ASP.NET and MS SQL.&nbsp;&nbsp;Also developed a web front end in .net and java script which included access of our database on our server and setting up our IIS. </div>
<div class = "indent4paragraph">
06/08-11/10 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="h5"><a id ="HistPhoto" href="https://www.photometrics.com/">Photometrics</a>, Tucson, Az</span> &nbsp;&nbsp;&nbsp;
Provided application support software and system level drivers along with embedded programming.  Developed massive parallel processing of images in real time in our system 
driver using <a href="https://developer.nvidia.com/CUDA-zone" >CUDA</a>. &nbsp;&nbsp;Learned how to build and program the open 
source microscope controlling software known as <a href="http://www.micro-manager.org/">Micro Manager</a> and <a href="http://rsb.info.nih.gov/ij/">ImageJ</a> in order to add and enhance their support for our cameras. &nbsp;&nbsp;Developed a PID temperature controller on a 8051 micro controller and performed bug fixes on Motorola 6300 DSP controllers for several cameras.</div>
<div class = "indent4paragraph">
10/04-05/08 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<span class="h5"><a id="HistPhaseShift" href="https://www.kla-tencor.com/">ADE Phase-Shift, KLA-Tencor</a>, Tucson, AZ</span> &nbsp;&nbsp;&nbsp;
Designed and implemented software for technically complex high precision optical metrology instrumentation in a team environment.  &nbsp;&nbsp;This included doing Windows scientific programming for spatial imaging and analysis, hardware interfacing including camera picture acquisition, graphical display and implementing user manipulations of images including Fourier and Zernike transforms, and control of hardware all using Visual Studio 6.0 in C++ with MFC.</div>
<div class = "indent4paragraph">
9/98-2/04 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<span class="h5"><a id="HistProtein" href="https://www.gyrosproteintechnologies.com/protein-technologies">Protein Technologies, Inc.</a>,   Tucson, AZ</span> &nbsp;&nbsp;&nbsp;
Developed applications and embedded software to automate chemistry for Protein and DNA synthesis using Visual Studio 6.0 and then Visual Studio.net with VB and C++ on Windows 95, 98, NT, and 2000/XP respectively.&nbsp;&nbsp;I used OOA and OOD to design a multi-threaded MVC application using Microsoft's SQL through ADO and integrated with Crystal Reports and implemented it in C++. &nbsp;&nbsp;I also  used com objects both third party and my own, did instrument/hardware control, and developed tcp/ip and rs232 communications between instrument and application.</div>
<div class = "indent4paragraph">
10/96-11/97 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<span class="h5"><a id="HistScientificTech">Scientific Technologies Corporation</a>, Tucson, AZ</span> &nbsp;&nbsp;&nbsp;
Developed an ODBC intensive application using MS Visual C++ 5.0 that could dynamically convert databases from several different formats into a Paradox database and with dynamic Crystal Reports 
reporting functions. &nbsp;&nbsp;Designed and created an application using Arc Info's GIS software to help coordinate and manage the sewer database for the City of Phoenix. 
</div>
<div class = "indent4paragraph">
11/94-10/96 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<span class="h5"><a id="HistSunquest">Sunquest Information Systems</a>, Tucson, AZ </span>&nbsp;&nbsp;&nbsp;
Windows 3.1, NT and 95 development using MS Visual C++ 1.5 through 4.2.  Duties included helping to analyze the existing Sunquest MUMPS and Unix systems and then design the new server network interface to MUMPS, and the design and coordination of our final products.  Initiated and taught a class in MFC to fellow programmers. &nbsp;&nbsp;Was a major contributor to the design and coding of the Differential Blood Analysis and WYSIWYG reporting software.
</div>
<div class = "indent4paragraph">
2/94-10/94 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	<span class="h5"><a>Kensal Corporation</a>, Tucson, AZ</span> &nbsp;&nbsp;&nbsp;
Windows NT software development, using MS Visual C++ 1.0.  Encapsulated Windows in a C++  paradigm and then developed a memory mapped multi-threaded MDI graphics application for doing 3D morphological transforms. &nbsp;&nbsp;Developed color compression algorithms on the Apple Macintosh.
</div>
<div  style="margin-top: 1% ;">
<a class = "indent4">Database Programmer</a>
</div>	
<div class = "indent4paragraph">
	5/86 - 4/92 &nbsp; Worked in several manufacturing environments on the <a href="http://en.wikipedia.org/wiki/IBM_System/38">IBM Sys38/AS400</a> in RPG III and SQL.  These systems use an Oracle type database embedded in the hardware, called DB 2.  I worked on projects by myself and in teams, to develop new software, to add features to the canned manufacturing software packages, and modify and maintain the software for day to day plant operation. Worked on BPCS, Pansophic, Data 3 and J.D. Edwards� software packages.
	<div style="padding-left: 7%">
		<ul ID="BulletedList4">
			<li>2/90-4/92	Advanced Information Systems, Seattle, WA</li>
			<li>4/89-11/89	Weigh-tronix, Santa Rosa, CA</li>
			<li>11/88-4/89	CommAir Mechanical, Oakland, CA</li>
		</ul>
	</div>
</div>

<div class = "indent4paragraph">
		5/86-1/88	 &nbsp;<span class="h5"><a id="HistSierra" >City of Sierra Vista</a>, Sierra Vista, AZ.</span> Developed a Geographic Information System (GIS) on IBM Sys 38.  Responsibilities included over all project management; the research, design, and development, including the design of the Relational Database and a GUI Interface.
</div>
</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button class="close" mat-button [mat-dialog-close]='true' (click)="closeDialog()">Close</button> 
	<button class="close" mat-button
		(click)="closeDialog()">Download</button>
</mat-dialog-actions>
